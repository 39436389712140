import React, { useState, useEffect } from 'react';
import every from 'lodash/every'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

const classes = {
  appDiv: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%"
  },
  progress: {
    width: "50vmin",
    height: "50vmin"
  },
  coinGrid: {
    display: "grid",
    justifyItems: "center",
    alignItems: "center",
    width: "90vmin"
  },
  coinStyle: {
    width: "100%"
  }
}

export default function() {
  const [numCoins, setNumCoins] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [lastTaps, setLastTaps] = useState([])
  const [lastUpdate, setLastUpdate] = useState(0)

  const columns = Math.ceil(Math.sqrt(numCoins))

  const handleKeyDown = function(event) {
    if (event.code === 'KeyA') {
      setDialogOpen(true)
      event.preventDefault()
    }
  }
  const handleMouseDown = function(event) {
    const newTaps = lastTaps.concat(Date.now()).slice(-7)
    const delays = newTaps.map((t, i) => i === 0 ? null : t - newTaps[i-1]).slice(1)
    setLastTaps(newTaps)
    
    if (delays.length === 6) {
      // Mario Theme
      // TODO: Clean up this detection code
      const eighths = [delays[0], delays[3]]
      const quarters = [delays[1], delays[2], delays[4]]
      const halves = [delays[5]]
      
      if(every(quarters, q => every(eighths, e => e < q)) && every(halves, h => every(quarters, q => q < h))) {
        setDialogOpen(true)
      } else {
        console.log(`${eighths} < ${quarters} < ${halves}`)
      }
    } else {
      console.log(`Delays: ${delays}`)
    }
  }

  const refreshCoins = function() {
    fetch('/api/coins', {headers: {'Accept': 'application/json'}})
    .then(r => r.json())
    .then(r => {
      if (r.time > lastUpdate) {
        console.log(`${r.time} > ${lastUpdate}`)
        setNumCoins(r.coins)
        setLastUpdate(r.time)
      }
    })
  }

  const saveNumCoins = function() {
    fetch('/api/coins', {
      method: 'PUT',
      headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
      body: JSON.stringify({coins: numCoins})
    })
    .then(r => r.json())
    .then(r => {
      setNumCoins(r.coins)
      setLastUpdate(r.time)
      setDialogOpen(false)
    })
  }

  useEffect(() => {
    refreshCoins()
  }, [])

  useEffect(() => {
    const refreshInterval = window.setInterval(refreshCoins, 1000)
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('mousedown', handleMouseDown)
    return () => {
      window.clearInterval(refreshInterval)
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('mousedown', handleMouseDown)
    }
  })

  if (numCoins == null) {
    return <div style={classes.appDiv}><CircularProgress style={classes.progress}/></div>
  }

  return (
    <div style={classes.appDiv}>
      <div style={{...classes.coinGrid, gridTemplateColumns: `repeat(${columns}, auto)`}}>
        {numCoins > 0 && [...Array(numCoins).keys()].map(i => <img key={i} src="/coin.svg" alt="" style={classes.coinStyle}></img>)}
      </div>
      <Dialog open={dialogOpen} onClose={()=>setDialogOpen(false)}>
        <DialogContent>
          <div style={{display: "flex"}}>
            <Button variant="contained" onClick={()=>setNumCoins(numCoins-1)}>-1</Button>
            <Box mx={3} width="4em">
              <TextField required label="Coins" value={numCoins} onChange={event => setNumCoins(parseInt(event.target.value))} type="number" />
            </Box>
            <Button variant="contained" onClick={()=>setNumCoins(numCoins+1)}>+1</Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setDialogOpen(false); setLastUpdate(0)}}>Nevermind</Button>
          <Button onClick={saveNumCoins} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
